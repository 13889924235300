import { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
  SubPage,
  Title,
  LightSubTitle,
  Text,
  SOSMessage,
  Divider,
  VerticalProgressView,
  MapContainer,
  View,
  useCustomerCoords,
  useSndDriverAddress,
  CustomerMarker,
  DriverMarker,
  // LocationMarker,
} from '@adac/core-view';

import { CaseStatusList, __, getClaimReference } from '@adac/core-model';

import StoresContext from '../../stores';
import { Icon } from '../ui';
import { useStatusListeners } from '../../hooks/useStatusListeners';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';

const SOSMessageStyles = styled(View)`
  border-top: solid 1px ${(props) => props.theme.colors.divider};
  & > * {
    padding-top: 0;
  }
`;

const Subtitle = styled(LightSubTitle)`
  color: ${(props) => props.theme.colors.text};
`;

const SmallText = styled(Text)`
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray};
  margin-top: 8px;
`;

const StatusIcon = styled.span`
  #pending-circle {
    transform-origin: 13.4px 13.4px;
    animation: roate-circle 3s linear infinite;
  }
  @keyframes roate-circle {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const ProgressIcon = ({
  progress,
  index,
}: {
  progress: number;
  index: number;
}) => {
  const iconName = (progressNumber: number, indexNumber: number): string => {
    if (progressNumber < indexNumber) return 'lock-closed';
    if (progressNumber > indexNumber) return 'lock-open';
    return 'lock-pending';
  };
  return (
    <StatusIcon>
      <Icon
        name={iconName(progress, index)}
        color={progress > index ? 'progress' : ''}
      />
    </StatusIcon>
  );
};

export default observer(() => {
  const { case: caseStore } = useContext(StoresContext);

  useStatusListeners(caseStore);

  const progressStages = [
    '',
    CaseStatusList.SND_SELECTED,
    CaseStatusList.DRIVER_SELECTED,
  ];
  const progress = progressStages.findIndex((stage) =>
    [''].concat(stage).includes(caseStore.status)
  );

  const customerCoords = useCustomerCoords({ caseStore, ready: progress < 3 });
  const sndDriverCoords = useSndDriverAddress({
    caseStore,
    ready: progress >= 3,
  });

  const customerMarker = customerCoords && (
    <MapContainer
      customerPosition={customerCoords}
      customerIcon={(props) => <CustomerMarker {...props} animation />}
    />
  );

  const driverMarker = sndDriverCoords && (
    <MapContainer
      customerPosition={sndDriverCoords}
      customerIcon={(props) => <DriverMarker {...props} iconName='checkmark' />}
    />
  );

  const stepConfig = [
    { title: 'Wir haben Ihren Auftrag erhalten', content: null },
    {
      title: 'Wir kümmern uns um Ihr Anliegen',
      content: customerMarker,
    },
    {
      title: 'Wir suchen Ihnen den nächsten Helfer',
      content: customerMarker,
    },
    {
      title: <Link to='/progress/ontheway'>Ihr Helfer fährt jetzt los</Link>,
      content: driverMarker,
    },
  ];

  const progressItems = stepConfig.map(({ title }, index) => ({
    icon: <ProgressIcon progress={progress} index={index} />,
    title,
  }));

  const isInsurance = useIsInsuranceProduct();

  return (
    <>
      <SubPage>
        <Title>
          {__('thankYou')} {__('letsGetStart')}
        </Title>
        {!isInsurance && (
          <Subtitle big={false}>
            {__('yourOrderNumber')}{' '}
            {getClaimReference(caseStore.commissioningReference)}
          </Subtitle>
        )}
        <SmallText>{__('ifYouWouldCancel')}</SmallText>
      </SubPage>
      <Divider />

      {stepConfig[progress] && stepConfig[progress].content}
      {progress >= stepConfig.length &&
        sndDriverCoords !== null &&
        driverMarker}
      <SubPage>
        <VerticalProgressView progress={progress} items={progressItems} />
      </SubPage>

      <SubPage>
        <SOSMessageStyles>
          <SOSMessage>
            <Icon name='sms' />
            <Text paddingLeft='10px'>
              {__('smsWhenHelpIsOnTheWay')}
              <strong> {__('inAbout5Minutes')} </strong>
            </Text>
          </SOSMessage>
        </SOSMessageStyles>
      </SubPage>
    </>
  );
});
